.app {
  text-align: center;
  background-color: #1FB25F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  font-family: 'Quicksand', sans-serif;
}

.app-info {
  padding-top: 210px;
}

.contact {
  background-color: #A9EED1;
  border-radius: 5px;
  max-width: 80%;
  max-height: 90%;
  margin: auto;
}

.contact-links {
  display: grid;
  grid-template-columns: 44% 1fr;
  align-items: right;
  justify-content: right;
  text-align: left;
}

.contact-links img {
  margin-right: 5px;
}

.contact-links a {
  margin-top: auto;
  margin-bottom: auto;
}

.contact img {
  width: 500px;
}

.navbar {
  background-color: #C9F5D9;
  border-style: solid;
  border-color: #C9F5D9 #C9F5D9 black;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px 150px;
  overflow: hidden;
  position: fixed;
  z-index: 2;
}

.logo {
  grid-area: 1 / 2 / span 2 / span 1;
}

.logo img {
  max-width: 500px;
}

.links {
  grid-area: 1 / 3 / span 2 / span 1;
}

#hamburger-links {
  display: none;
  position: absolute;
  right: -100px;
  top: 170px;
}

.animated-menu {
  -webkit-animation: menu-start 0.5s forwards;
  animation: menu-start 0.5s forwards;
}

.links a {
  color: black !important;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.links a.icon {
  background: #C9F5D9;
  position: absolute;
  right: 0;
  top: 0;
}

.insta {
  position: absolute;
  right: 70px;
  top: 5px;
  padding: 6px 4px !important;
}

.twitter {
  position: absolute;
  right: 130px;
  top: 7px;
  padding: 10px 10px !important;
}

.small-icon {
  width: 43px;
}

.links a:hover {
  background-color: #AFF0F0;
  color: #417DB0 !important;
  border-radius: .5rem;
}

.bars {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.nav-quotes {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
}

.two-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.two-grid img {
  max-height: 80%;
  max-width: 90%;
}

.three-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.three-grid img {
  max-height: 80%;
  max-width: 90%;
}

.nav-quotes img {
  display: flex;
  grid-area: 1 / 2 / span 2 / span 1;
}

.app-link {
  color: #61dafb;
}

.quote {
  font-size: calc(6px + 1vmin);
}

.single-photo {
  width: max-content;
}

.small-single-photo {
  max-width: 50%;
  max-height: 50%;
}

.carousel-holder {
  max-width: 50%;
  margin: auto;
}

.card-holder {
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
}

.card {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 80%;
  border-style: solid;
  border-color: #417DB0;
  border-radius: 10px;
}

.card-img {
  max-height: 200px;
}

.cart-button {
  padding: .5em 1em .5em;
}

.disabled {
  background-color: #81b69d;
  border-color: #81b69d;
  cursor: default;
}

.hide {
  display: none;
}

.show {
  display: block;
  font-size: medium;
}

.ingredients {
  font-size: large;
}

@media only screen and (max-width: 500px) {
  #hamburger-links a {
    padding: 4px 6px;
  }

  .contact-links {
    grid-template-columns: 30% 1fr;
  }

  .logo {
    grid-area: 1 / 1 / span 2 / span 3;
  }

  .twitter {
    right: 320px;
  }

  .insta {
    right: 320px;
    top: 50px;
  }

  .carousel-holder {
    max-width: 100%;
  }

  .card-holder {
    grid-template-columns: 1fr;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes menu-start {
  100% {right:0};
}

@-webkit-keyframes menu-start {
  100% {right:0};
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 0.9;}
}

@-moz-keyframes fade-in {
0% {opacity: 0;}
100% {opacity: 0.9;}
}

@-webkit-keyframes fade-in {
0% {opacity: 0;}
100% {opacity: 0.9;}
}

@-o-keyframes fade-in {
0% {opacity: 0;}
100% {opacity: 0.9;}
}

@-ms-keyframes fade-in {
0% {opacity: 0;}
100% {opacity: 0.9;}
}

img {
  max-width: 80%;
  max-height: 90%;
  margin: auto;
  border-radius: 5px;
}

button {
  max-width: 80%;
  padding: 1em 1.5em 1em;
  border-radius: .5rem;
  color: black;
  border-color: #8FDDDF;
  background-color: #8FDDDF;
  margin: auto;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
}